<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Lịch sử chương trình Xúc tiến đầu tư của Bộ, cơ quan ngang Bộ
        </CCardHeader>
        <CCardBody>
          <div class="position-relative table-responsive">
            <table class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr>
                <th class="position-relative pr-4">STT</th>
                <th class="position-relative pr-4">Phiên bản</th>
                <th class="position-relative pr-4">Số văn bản</th>
                <th class="position-relative pr-4">Năm</th>
                <th class="position-relative pr-4">Ngày văn bản</th>
                <th class="position-relative pr-4">Đánh giá</th>
                <th class="position-relative pr-4">Định hướng</th>
                <th class="position-relative pr-4">Dự kiến</th>
                <th class="position-relative pr-4">Các tổ chức</th>
                <th class="position-relative pr-4">Ngày chỉnh sửa</th>
                <th class="position-relative pr-4">Chi tiết phụ lục</th>
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="historyData">
                <tr v-for="(item, index) in historyData" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.version }}</td>
                  <td>{{ item.soVanBan }}</td>
                  <td>{{ item.nam_ChuongTrinh }}</td>
                  <td>{{ formatDateData(item.ngayVanBan) }}</td>
                  <td>{{ item.danhGiaHoatDongNamTruoc }}</td>
                  <td>{{ item.quanDiemDinhHuongMucTieu }}</td>
                  <td>{{ item.duKienChuongTrinh }}</td>
                  <td>{{ item.toChucThucHien }}</td>
                  <td>{{ formatDateData(item.modifiedDate) }}</td>
                  <td :rowspan="historyData.length" class="align-content-center text-center">
<!--                    <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết">-->
<!--                      <CIcon name="cil-options"/>-->
<!--                    </CButton>-->
                    <CButton size="sm" color="info" class="mr-2" @click="toggleDetailPhuLucModal({show:true})" title="Chi tiết điều chỉnh">
                      <CIcon name="cil-options"/>
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="historyData.length === 0">
                <td colspan="11">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <DeXuatCTXTDTPhuLucDetailModal2 />
  </CRow>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { displayLabel } from '@/shared/appConstants'
import DeXuatCTXTDTPhuLucDetailModal2 from '@/components/DeXuatCTXTDT_BoNganh/DeXuatCTXTDTPhuLucDetailModal2'
import { TOGGLE_DETAIL_MODAL } from '@/store/modules/DeXuatCTXTDT_BoNganh/actionTypes'

export default {
  name: 'DeXuatCTXTDTBoNganhHistory',
  components: {
    DeXuatCTXTDTPhuLucDetailModal2
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDT_BoNganh',
      isLoading: false,
      itemId: -1,
      idprop: -1
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDT', {
      // isPhuLucModalOpen: 'isHistoryModalOpen',
      historyData: 'historyData',
      historyDetailData: 'historyDetail',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDT', {
      getListHistory: types.GET_HISTORY,
      getHistoryDetail: types.GET_HISTORYDETAIL,
      toggleDetailPhuLucModal: TOGGLE_DETAIL_MODAL,
      toggleModal: types.TOGGLE_HISTORY_MODAL
    }),
    displayLabel,
    async fetchListItem (id) {
      await this.getListHistory(id)
      await this.getHistoryDetail(id)
    },
    viewItem (item) {
      // this.idprop = item.id
      // this.toggleHistoryDetailModal({ show: true })
      // window.open(`/de-xuat-ct-xtdt/${item.id}/chi-tiet-dieu-chinh`, '_blank')
      this.$router.push({ path: `${item.id}/chi-tiet-dieu-chinh` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    await this.fetchListItem(this.$route.params.id)
  },
  watch: {
    // item: async function (val) {
    //   this.itemId = val.id
    //   await this.fetchListItem(this.itemId)
    // }
  }
}
</script>

<style scoped>

</style>
